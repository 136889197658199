import { createClient as createManagementClient } from 'contentful-management'
import { createClient as createDeliveryClient } from 'contentful'

export const managementClient = createManagementClient({
	accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_CONTENT_MANAGEMENT_ACCESS_TOKEN as string
})

export const deliveryClient = createDeliveryClient({
	space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID as string,
	accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_CONTENT_DELIVERY_ACCESS_TOKEN as string
})
