import { api } from 'clients/axios'

export async function getMetadataFromProvider(accessToken: string) {
	try {
		const metadata = await api.get('https://graph.microsoft.com/v1.0/me', {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})

		return { title: metadata.data.jobTitle || '', phone: metadata.data.mobilePhone || '' }
	} catch (error: any) {
		console.log(error.message)
		return { title: '', phone: '' }
	}
}
