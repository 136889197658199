import { getAdditionalUserInfo, getIdToken, OAuthProvider, signInWithPopup } from 'firebase/auth'
import { doc, setDoc, Timestamp } from 'firebase/firestore'
import { auth, microsoftProvider, UsersCollection } from '../../clients/firebase-client'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { api } from 'clients/axios'
import { getPhotoFromProviderAndUpload } from 'functions/auth/getPhotoFromProviderAndUpload'
import { getMetadataFromProvider } from 'functions/auth/getMetadataFromProvider'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/nextjs'

const defaultSalesforceAccountId = '0016S000034vc65QAA'

export async function handleMicrosoftSignup() {
	try {
		// Signin with Microsoft Provider
		const result = await signInWithPopup(auth, microsoftProvider)
		const user = result.user

		// Get additionalUserInfo
		const additionalUserInfo = getAdditionalUserInfo(result)

		// Check to see if they are a new user
		const isNewUser = additionalUserInfo?.isNewUser

		if (isNewUser) {
			// Assign the user an accessLevel of 2
			const token = await getIdToken(user)
			await api.post(
				`/api/v1/firebase/user/set-access-level`,
				{
					uid: user.uid,
					accessLevel: 2
				},
				{ headers: { Authorization: process.env.NEXT_PUBLIC_EXTERNAL_AUTH_TOKEN } }
			)

			// Create a credentail from the user result
			const credential = OAuthProvider.credentialFromResult(result)

			// Get the access token from the credential
			const accessToken = credential?.accessToken as string

			// Run the getPhotoFromProviderAndUpload and save the url
			const photoUrl = await getPhotoFromProviderAndUpload(user, accessToken)

			// Run the getMetadataFromProvider and save the metadata
			const metadata = await getMetadataFromProvider(accessToken)

			// Check Salesforce to see if employee is a contact
			const contact: AxiosResponse<string> = await api.get(
				`/api/v1/salesforce/contact/retrieve?email=${user.email}`
			)
			let contactId = contact.data

			// If they are not a contact, add them as a contact to the ARC Quotes District account
			if (!contactId) {
				const contact: AxiosResponse<string> = await api.post(
					`/api/v1/salesforce/contact/create`,
					{
						firstName: user.displayName ? user.displayName.split(' ')[0] : '',
						lastName: user.displayName ? user.displayName.split(' ')[1] : '',
						email: user.email,
						salesforceAccountId: defaultSalesforceAccountId
					},
					{ headers: { Authorization: token } }
				)
				contactId = contact.data
			}

			// Create a document in the database for the user with the same uid
			await setDoc(doc(UsersCollection, user.uid), {
				email: user.email || '',
				firstName: user.displayName ? user.displayName.split(' ')[0] : '',
				lastName: user.displayName ? user.displayName.split(' ')[1] : '',
				id: user.uid,
				createdAt: Timestamp.now(),
				lastActive: Timestamp.now(),
				accessLevel: 2,
				role: 'Employee',
				salesforceAccountId: defaultSalesforceAccountId,
				salesforceContactId: contactId,
				profileComplete: true,
				title: metadata ? metadata.title : '',
				phone: metadata ? metadata.phone : '',
				photo: photoUrl || ''
			})
			if (localStorage.getItem('returnTo')) {
				const returnTo = localStorage.getItem('returnTo')
				localStorage.removeItem('returnTo')
				window.location.href = `${
					process.env.DEPLOY_PRIME_URL || process.env.NEXT_PUBLIC_API_BASE_URL
				}${returnTo}`
			} else window.location.href = '/profile'
		} else {
			if (localStorage.getItem('returnTo')) {
				const returnTo = localStorage.getItem('returnTo')
				localStorage.removeItem('returnTo')
				window.location.href = `${
					process.env.DEPLOY_PRIME_URL || process.env.NEXT_PUBLIC_API_BASE_URL
				}${returnTo}`
			} else window.location.href = '/profile'
		}
	} catch (error: any) {
		if ((error.message = 'auth/popup-closed-by-user')) {
			window.location.reload()
			return
		}
		Sentry.captureException(error, {
			tags: { role: 'Employee' },
			extra: { pageName: 'Employee Signup', action: 'Microsoft Signup' }
		})
		LogRocket.captureException(error, {
			tags: { role: 'Employee' },
			extra: { pageName: 'Employee Signup', action: 'Microsoft Signup' }
		})
		toast.error(error.message)
		console.log(error.message)
	}
}
