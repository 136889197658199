import React, { useState } from 'react'
interface ToggleProps {
	type: 'customer' | 'employee'
	setType: (type: 'customer' | 'employee') => void
}

export function SignupTypeToggle({ type, setType }: ToggleProps) {
	return (
		<div className="mb-4 flex items-center gap-5 lg:mb-10">
			<button
				className={`${
					type === 'customer' ? 'rounded bg-ab-50 px-6 py-3 text-ab-100' : 'text-slate-400'
				} text-sm font-medium tracking-wide`}
				onClick={() => setType('customer')}
			>
				Customer
			</button>
			<button
				className={`${
					type === 'employee' ? 'rounded bg-ab-50 px-6 py-3 text-ab-100' : 'text-slate-400'
				} text-sm font-medium tracking-wide`}
				onClick={() => setType('employee')}
			>
				ARC Employee
			</button>
		</div>
	)
}

export function useSignupType() {
	const [type, setType] = useState<'customer' | 'employee'>('customer')

	return { type, setType, SignupTypeToggle }
}
