import { api } from 'clients/axios'
import { managementClient } from 'clients/contentful-client'
import { User } from 'firebase/auth'

export async function getPhotoFromProviderAndUpload(user: User, accessToken: string) {
	try {
		const photo = await api.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			responseType: 'arraybuffer'
		})

		const space = await managementClient.getSpace(process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID as string)
		const env = await space.getEnvironment('production')
		const asset = await env.createAssetFromFiles({
			fields: {
				title: {
					'en-US': user.displayName ? `${user.displayName} | Photo` : `${user.email} | Photo`
				},
				description: {
					'en-US': ''
				},
				file: {
					'en-US': {
						contentType: 'image/jpeg',
						fileName: user.displayName ? `${user.displayName}.jpg` : `${user.email}.jpg`,
						file: photo.data
					}
				}
			}
		})
		await asset.processForAllLocales()
		const image = await env.getAsset(asset.sys.id)
		await image.publish()
		const url = `https:${image.fields.file['en-US'].url}`
		return url
	} catch (error: any) {
		console.log(error.message)
		return ''
	}
}
