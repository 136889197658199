import React from 'react'

export default function SignupLoginBanner() {
	return (
		<div className="relative hidden w-5/12 items-center bg-ab-100 lg:flex">
			<div className="relative z-10 px-10">
				<h1 className="mb-4 text-5xl font-black text-white">Your one-stop shop for all ARC Products</h1>
				<p className="text-lg text-white">
					From exciting new books, to toolkits and skills cards, we have made it easy to equip your classrooms
					with all the materials you need to continue your ARC implementation.
				</p>
			</div>
			<div
				className="absolute top-0 left-0 z-0 h-full w-full bg-center opacity-[15%] filter"
				style={{ backgroundImage: 'url(/images/books-bg.jpg)' }}
			></div>
		</div>
	)
}
